<template>
  <v-card>
    <l-map
      ref="mapRef"
      :zoom.sync="zoom"
      :options="mapOptions"
      :center="center"
      :bounds="bounds"
      :scrollWheelZoom="false"
      style="height: 700px; width: 100%;z-index: 0"


    >
<!--      <l-circle-marker
        :center="center"
        :radius="50"
        :fill="true"
        :fill-opacity="0.5"
        fill-color="#ff0000"
        color="#ff0000"
      />-->
<!--      <l-circle
        :center="center"
        :radius="50"
        :fill="true"
        :fill-opacity="0.2"
        fill-color="#ff0000"
        color="#ff0000"
      />-->
<!--      <l-control-layers
        :position="layersPosition"
        :collapsed="false"
        :sort-layers="true"
        @click="newMarker"
      />-->
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        :token="token"
        layer-type="base"
      />
<!--      <l-control-zoom :position="zoomPosition" />-->
      <l-control-attribution
        :position="attributionPosition"
        :prefix="attributionPrefix"
      />
<!--      <l-control-scale :imperial="imperial" />-->

      <l-marker
        v-for="marker in points"
        :key="marker.id"
        :visible="marker.visible"
        :draggable="marker.draggable"
        :lat-lng.sync="marker.position"
        :icon="marker.icon"
      >
        <l-popup >
          <avatar-name :options="{avatar: marker.avatar, label: marker.label, email: marker.email, address: marker.address, tel: marker.tel, marker: true, qualities: marker.qualities, type: marker.type }" />
        </l-popup>
        <l-tooltip :content="marker.tooltip" />
      </l-marker>
      <!--      <l-layer-group
        layer-type="overlay"
        name="Layer polyline"
      >
        <l-polyline
          v-for="item in polylines"
          :key="item.id"
          :lat-lngs="item.points"
          :visible="item.visible"
          @click="alert(item)"
        />
      </l-layer-group>
      <l-layer-group
        v-for="item in stuff"
        :key="item.id"
        :visible.sync="item.visible"
        layer-type="overlay"
        name="Layer 1"
      >
        <l-layer-group :visible="item.markersVisible">
          <l-marker
            v-for="marker in item.markers"
            :key="marker.id"
            :visible="marker.visible"
            :draggable="marker.draggable"
            :lat-lng="marker.position"
            @click="alert(marker)"
          />
        </l-layer-group>
        <l-polyline
          :lat-lngs="item.polyline.points"
          :visible="item.polyline.visible"
          @click="alert(item.polyline)"
        />
      </l-layer-group>-->
    </l-map>
  </v-card>
</template>

<script>
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import {
  mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle, mdiPencil,
} from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { computed, ref, watch } from '@vue/composition-api'

/* eslint-disable global-require */

// eslint-disable-next-line object-curly-newline
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
  LCircleMarker,
  LCircle,
} from 'vue2-leaflet'
import { icon, Icon, latLngBounds } from 'leaflet'
import Controller from './Controller'
import 'leaflet/dist/leaflet.css'
import store from '@/store'
import HeaderFilters from '@/components/HeaderFilters'
import DatePicker from '@/components/DatePicker'
import DateRangePicker from '@/components/DateRangePicker'
import AvatarName from '@/components/AvatarName'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */
/* eslint-disable global-require */

const markers1 = [
  {
    position: { lng: -1.219482, lat: 47.41322 },
    visible: true,
    draggable: true,
  },
  { position: { lng: -1.571045, lat: 47.457809 } },
  { position: { lng: -1.560059, lat: 47.739323 } },
  { position: { lng: -0.922852, lat: 47.886881 } },
  { position: { lng: -0.769043, lat: 48.231991 } },
  { position: { lng: 0.395508, lat: 48.268569 } },
  { position: { lng: 0.604248, lat: 48.026672 } },
  { position: { lng: 1.2854, lat: 47.982568 } },
  { position: { lng: 1.318359, lat: 47.894248 } },
  { position: { lng: 1.373291, lat: 47.879513 } },
  { position: { lng: 1.384277, lat: 47.798397 } },
  { position: { lng: 1.329346, lat: 47.754098 } },
  { position: { lng: 1.329346, lat: 47.680183 } },
  { position: { lng: 0.999756, lat: 47.635784 } },
  { position: { lng: 0.86792, lat: 47.820532 } },
  { position: { lng: 0.571289, lat: 47.820532 } },
  { position: { lng: 0.439453, lat: 47.717154 } },
  { position: { lng: 0.439453, lat: 47.61357 } },
  { position: { lng: -0.571289, lat: 47.487513 } },
  { position: { lng: -0.615234, lat: 47.680183 } },
  { position: { lng: -0.812988, lat: 47.724545 } },
  { position: { lng: -1.054688, lat: 47.680183 } },
  { position: { lng: -1.219482, lat: 47.41322 } },
]

const poly1 = [
  { lng: -1.219482, lat: 47.41322 },
  { lng: -1.571045, lat: 47.457809 },
  { lng: -1.560059, lat: 47.739323 },
  { lng: -0.922852, lat: 47.886881 },
  { lng: -0.769043, lat: 48.231991 },
  { lng: 0.395508, lat: 48.268569 },
  { lng: 0.604248, lat: 48.026672 },
  { lng: 1.2854, lat: 47.982568 },
  { lng: 1.318359, lat: 47.894248 },
  { lng: 1.373291, lat: 47.879513 },
  { lng: 1.384277, lat: 47.798397 },
  { lng: 1.329346, lat: 47.754098 },
  { lng: 1.329346, lat: 47.680183 },
  { lng: 0.999756, lat: 47.635784 },
  { lng: 0.86792, lat: 47.820532 },
  { lng: 0.571289, lat: 47.820532 },
  { lng: 0.439453, lat: 47.717154 },
  { lng: 0.439453, lat: 47.61357 },
  { lng: -0.571289, lat: 47.487513 },
  { lng: -0.615234, lat: 47.680183 },
  { lng: -0.812988, lat: 47.724545 },
  { lng: -1.054688, lat: 47.680183 },
  { lng: -1.219482, lat: 47.41322 },
]

const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: true,
    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  {
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
]

Vue.use(VueGoogleMaps, {
  load: {
    language: 'fr',
    key: 'AIzaSyC8_8YmWMNDqCSHzFI9A_7Xg5Miugh6rqo',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    /// / If you want to set the version, you can do so:
    // v: '3.26',
  },

  /// / If you intend to programmatically custom event listener code
  /// / (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  /// / instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  /// / you might need to turn this on.
  // autobindAllEvents: false,

  /// / If you want to manually install components, e.g.
  /// / import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  /// / Vue.component('GmapMarker', GmapMarker)
  /// / then disable the following:
  // installComponents: true,
})

export default {
  components: {
    AvatarName,
    DateRangePicker,
    DatePicker,
    HeaderFilters,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LCircleMarker,
    LCircle,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
  },
  props: {
    points: {
      type: [],
      default: [],
    },
    bounds: {
      type: [Object, Array],
    },
    center: {
      type: Object,
      default: () => { return { lat: 48.8534951, lng: 2.3483915 } }
    },
  },
  setup(props, { emit }) {
    const {
      markers,
      filters,
      addressChanged,
      latitude,
      longitude,
      bounds: bound,
    } = Controller()

    console.log('bound', bound.value)
    const dateRange = ref(null)
    /*const center = ref({ lat: 48.8534951, lng: 2.3483915 })*/
    const opacity = 0.6
    const token = 'your token if using mapbox'
    const mapOptions = {
      zoomControl: true,
      attributionControl: true,
      zoomSnap: true,
      scrollWheelZoom: false,
      useCtrlKey: true,
    }
    const zoom = 10
    const minZoom = 1
    const maxZoom = 20
    const zoomPosition = 'topleft'
    const attributionPosition = 'bottomright'
    const layersPosition = 'topright'
    const attributionPrefix = 'Vue2Leaflet'
    const imperial = false
    const Positions = ['topleft', 'topright', 'bottomleft', 'bottomright']
    const mapRef = ref()
    const boundsItem = computed({
      get: () => {
        console.log('bounddddd', props.bounds)

        return props.bounds
      },
      set: value => emit('update:bounds', value),
    })
    const centerItem = computed({
      get: () => {
        /*mapRef.value.fitBounds([
          [props.center.lat - props.center.radius || 50, props.center.lng - props.center.radius || 50],
          [props.center.lat + props.center.radius || 50, props.center.lng + props.center.radius || 50],
        ])*/
        return props.center
      },
      set: value => emit('update:center', value),
    })
    const polylines = [
      {
        id: 'p1',
        points: [
          { lat: 37.772, lng: -122.214 },
          { lat: 21.291, lng: -157.821 },
          { lat: -18.142, lng: -181.569 },
          { lat: -27.467, lng: -206.973 },
        ],
        visible: true,
      },
      {
        id: 'p2',
        points: [
          [-73.91, 40.78],
          [-87.62, 41.83],
          [-96.72, 32.76],
        ],
        visible: true,
      },
    ]

    const stuff = [
      {
        id: 's1',
        markers: markers1,
        polyline: { points: poly1, visible: true },
        visible: true,
        markersVisible: true,
      },
    ]

    const alert = item => {
      // eslint-disable-next-line no-unused-vars
      alert(`this is ${JSON.stringify(item)}`)
    }

    const addMarker = latlng => {
      const newMarker = {
        position: latlng,
        draggable: true,
        visible: true,
      }
      markers.value.push(newMarker)
    }

    const removeMarker = index => {
      markers.value.splice(index, 1)
    }
    const newMarker = e => {
      console.log('position', props.points)
      addressChanged({ lat: e.latlng.lat, lon: e.latlng.lng })
      emit('changed', { lat: e.latlng.lat, lon: e.latlng.lng })
      addMarker(e.latlng)
    }

/*    watch([centerItem], () => {
      console.log("points loaded again")
      this.$refs.mapRef.fitBounds([
        [props.center.lat - props.center.radius, props.center.lng - props.center.radius],
        [props.center.lat + props.center.radius, props.center.lng + props.center.radius],
      ]);
    }, { deep: true })*/

    return {
      dateRange,
      filters,
      centerItem,
      opacity,
      token,
      mapOptions,
      zoom,
      minZoom,
      maxZoom,
      zoomPosition,
      attributionPosition,
      layersPosition,
      attributionPrefix,
      imperial,
      Positions,
      tileProviders,
      markers,
      polylines,
      stuff,
      alert,
      newMarker,
      addMarker,
      removeMarker,
      latitude,
      longitude,

    }
  },
}
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
